/*------------------------------------*\
  #PRINT STYLE
\*------------------------------------*/


@mixin print-style {
  @page {
    margin: 2cm 3.5cm;
  }
  body {
    font-size: 9pt;
    line-height: 1.4;
    color: #000000;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    font-style: normal;
  	font-variant: normal;
  	font-weight: 400;
    em,
    i{
      font-style: italic;
    }
  }
  h1 {
  	font-size: 20pt;
  }
  h2 {
  	font-size: 16pt;
  }
  h3 {
  	font-size: 14pt;
  }
  h4 {
  	font-size: 12pt;
    margin-bottom: .1cm;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1.1;
  }
  small {
    font-size: 7pt;
  }
  blockquote {
    text-align: center;
  }
  a {
    color: $base-color;
    border-bottom: .015cm solid rgba($base-color, .3);
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  em,
  i{
    font-style: italic;
    page-break-inside: auto;
  }
  b,
  strong{
    font-weight: 700;
    page-break-inside: auto;
  }
  em > b,
  b > em,
  i > b,
  b > i,
  em > strong,
  strong > em,
  i > strong,
  strong > i {
    font-style: italic;
    font-weight: 700;
  }
  hr {
    border: 0;
    height: 1px;
    background-color: $base-color;
    margin: .3cm 0;
  }
  .article {
    width: 100%;
    height: auto !important;
    margin: 0;
    display: block;
    padding: 0;
    overflow: visible;
    box-shadow: none;
  }
  .header,
  .footer,
  .section.print,
  .section.authors,
  .section.share {
    display: none !important;
  }
  body {
    background: #fff !important;
    color: #000;
  }
  .body {
    p {widows :3 ; orphans : 3 ; }
    a {page-break-inside:avoid}
    h1, h2, h3, h4, h5, h6 { page-break-after:avoid; page-break-inside:avoid }
    img {
      page-break-inside:avoid;
      max-width: 100%;
      height: auto;
    }
    table, pre { page-break-inside:avoid }
    a:link, a:visited, a {
      background: transparent;
      color: #2C51DF;
      font-weight: bold;
      text-decoration: underline;
      text-align: left;
    }
    a[href^=http]:after {content:" <" attr(href) "> ";}
    article a[href^="#"]:after { content: "";}
    a:not(:local-link):after {content:" <" attr(href) "> ";}
  }
  .footnotes {
    page-break-before: always;
    ol , ol > li {
      list-style: none;
    }
    ol {
      counter-reset:li;
      li {
        position: relative;
        padding-left: 1.8em;
        margin-bottom: .5em;
        &:before {
          content:counter(li);
          counter-increment:li;
          position: absolute;
          left: 0;
          color: rgba($base-color, .5);
        }
      }
    }
    li,
    p {
      font-size: 7pt;
      margin-bottom: 0;
      margin-top: 0;
      text-align: left;
    }
    li {
    }
  }
}
