/*------------------------------------*\
  #PAGE STYLES
\*------------------------------------*/

@import "modules/_colors.scss";
@import "partials/components/_background.scss";
@import "modules/_print-style.scss";
@include print-style;




/*------------------------------------*\
  #HTML-PDF
\*------------------------------------*/

@page :first {
  margin: 2cm 2cm;
  .p-footer {
    display: none;
  }
}
// body { font-family: DejaVu Serif; }
.p-article__first-page {
  page-break-after: always;
  h1,
  h2 {
    font-weight: normal;
  }
}

.p-logo {
  display: block;
  width: 4cm;
  height: auto;
  margin-bottom: 1cm;
}
.p-logo-2 {
  display: block;
  width: 25mm;
  height: auto;
  margin-top: 3mm;
}

.p-article__header {
  padding: 0;
  margin-bottom: 1cm;
  text-align: center;
  .p-article__title,
  .p-article__author,
  .p-article__revue {
    margin: 0;
  }
  .p-article__title {
    margin-top: 3mm;
    margin-bottom: 3mm;
  }
  p {
    text-align: center;
  }
}

.p-article__header {
  border-top: 1.5mm solid $base-color;
  border-bottom: 1.5mm solid $base-color;
  &.bg-dossier,
  &.bg-3 {
    border-color: $yellow;
    background-color: rgba($yellow, .4) !important;
  }
  &.bg-debats,
  &.bg-4 {
    border-color: $red;
    background-color: rgba($red, .2) !important;
  }
  &.bg-pop,
  &.bg-5 {
    border-color: $blue;
    background-color: rgba($blue, .2) !important;
  }
  &.bg-memoires,
  &.bg-6 {
    border-color: $orange;
    background-color: rgba($orange, .2) !important;
  }
  &.bg-theses,
  &.bg-7 {
    border-color: $green;
    background-color: rgba($green, .2) !important;
  }
}

.p-article-header__inner {
  padding: 1cm;

}
.p-article__info {
  p {
    margin-top: 0;
  }
  h4 {
    margin-top: .5cm;
    margin-bottom: .15cm;
  }
  hr {
    margin-top: 1cm;
  }
}

.p-sep {
  padding-bottom: .5cm;
  margin-bottom: .5cm;
  border-bottom: .015cm solid rgba($base-color, .3);
}

.p-footer {
  position: fixed;
  bottom: -2cm;
  left: 0;
  right: 0;
  height: 2cm;
  .page-number {
    text-align: center;
    display: block;
    width: 100%;
    &:after {
      content: counter(page);
    }
  }
}

.footnote-backref {
  font-size: 0;
}
.p-article__content {
  a.footnote-ref {
    color: $base-color;
    text-decoration: none;
    font-size: 7pt;
    padding-left: 1pt;
    vertical-align: super !important;
  }
  .intro {
    margin-bottom: 5mm;
    font-size: 11pt;
  }
  p {widows :3 ; orphans : 3 ; }
  ul,
  ol,
  li {
    margin: 0;
    padding: 0;
  }
  img {
    page-break-inside:avoid;
    max-width: 100%;
    height: auto;
  }
}

p {
  // text-align: justify;
  // text-justify: inter-word;
}

table {
  width: 100%;
  border-top: .02cm solid $base-color;
  border-bottom: .02cm solid $base-color;
  margin-top: 1cm;
  margin-bottom: 1cm;
  page-break-inside: avoid;
  thead {
    border-bottom: .02cm solid $base-color;
  }
  tbody tr {
    &:nth-child(odd) {
      background-color: rgba($base-color, .05);
    }
    &:nth-child(even) {
      background-color: rgba($base-color, .1);
    }
  }
  tfoot {
    border-top: 1mm solid $base-color;
  }
  th,
  td {
    padding: 2mm;
    text-align: left;
    vertical-align: top;
    font-size: 9pt;
  }
  th,
  th strong {
    font-size: 9pt;
  }
}
