/*------------------------------------*  #PAGE STYLES
\*------------------------------------*/
/*------------------------------------*  #COLORS
\*------------------------------------*/
/*------------------------------------*  #BACKGROUND
\*------------------------------------*/
/* line 6, resources/assets/css/partials/components/_background.scss */
.bg-dossier,
.bg-3 {
  background: #FFF5B7 !important; }

/* line 10, resources/assets/css/partials/components/_background.scss */
.bg-debats,
.bg-4 {
  background: #E2B2B2 !important; }

/* line 14, resources/assets/css/partials/components/_background.scss */
.bg-pop,
.bg-5 {
  background: #ABBDC8 !important; }

/* line 18, resources/assets/css/partials/components/_background.scss */
.bg-memoires,
.bg-6 {
  background: #F7CD96 !important; }

/* line 22, resources/assets/css/partials/components/_background.scss */
.bg-theses,
.bg-7 {
  background: #B0DFBF !important; }

/*------------------------------------*  #PRINT STYLE
\*------------------------------------*/
@page {
  margin: 2cm 3.5cm; }

/* line 10, resources/assets/css/modules/_print-style.scss */
body {
  font-size: 9pt;
  line-height: 1.4;
  color: #000000; }

/* line 15, resources/assets/css/modules/_print-style.scss */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  font-style: normal;
  font-variant: normal;
  font-weight: 400; }
  /* line 27, resources/assets/css/modules/_print-style.scss */
  h1 em,
  h1 i,
  h2 em,
  h2 i,
  h3 em,
  h3 i,
  h4 em,
  h4 i,
  h5 em,
  h5 i,
  h6 em,
  h6 i,
  p em,
  p i,
  ul em,
  ul i,
  ol em,
  ol i {
    font-style: italic; }

/* line 32, resources/assets/css/modules/_print-style.scss */
h1 {
  font-size: 20pt; }

/* line 35, resources/assets/css/modules/_print-style.scss */
h2 {
  font-size: 16pt; }

/* line 38, resources/assets/css/modules/_print-style.scss */
h3 {
  font-size: 14pt; }

/* line 41, resources/assets/css/modules/_print-style.scss */
h4 {
  font-size: 12pt;
  margin-bottom: .1cm; }

/* line 45, resources/assets/css/modules/_print-style.scss */
h1,
h2,
h3,
h4 {
  line-height: 1.1; }

/* line 51, resources/assets/css/modules/_print-style.scss */
small {
  font-size: 7pt; }

/* line 54, resources/assets/css/modules/_print-style.scss */
blockquote {
  text-align: center; }

/* line 57, resources/assets/css/modules/_print-style.scss */
a {
  color: #000000;
  border-bottom: 0.015cm solid rgba(0, 0, 0, 0.3);
  text-decoration: none; }

/* line 62, resources/assets/css/modules/_print-style.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-after: avoid; }

/* line 70, resources/assets/css/modules/_print-style.scss */
em,
i {
  font-style: italic;
  page-break-inside: auto; }

/* line 75, resources/assets/css/modules/_print-style.scss */
b,
strong {
  font-weight: 700;
  page-break-inside: auto; }

/* line 80, resources/assets/css/modules/_print-style.scss */
em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  font-style: italic;
  font-weight: 700; }

/* line 91, resources/assets/css/modules/_print-style.scss */
hr {
  border: 0;
  height: 1px;
  background-color: #000000;
  margin: .3cm 0; }

/* line 97, resources/assets/css/modules/_print-style.scss */
.article {
  width: 100%;
  height: auto !important;
  margin: 0;
  display: block;
  padding: 0;
  overflow: visible;
  box-shadow: none; }

/* line 106, resources/assets/css/modules/_print-style.scss */
.header,
.footer,
.section.print,
.section.authors,
.section.share {
  display: none !important; }

/* line 113, resources/assets/css/modules/_print-style.scss */
body {
  background: #fff !important;
  color: #000; }

/* line 118, resources/assets/css/modules/_print-style.scss */
.body p {
  widows: 3;
  orphans: 3; }

/* line 119, resources/assets/css/modules/_print-style.scss */
.body a {
  page-break-inside: avoid; }

/* line 120, resources/assets/css/modules/_print-style.scss */
.body h1, .body h2, .body h3, .body h4, .body h5, .body h6 {
  page-break-after: avoid;
  page-break-inside: avoid; }

/* line 121, resources/assets/css/modules/_print-style.scss */
.body img {
  page-break-inside: avoid;
  max-width: 100%;
  height: auto; }

/* line 126, resources/assets/css/modules/_print-style.scss */
.body table, .body pre {
  page-break-inside: avoid; }

/* line 127, resources/assets/css/modules/_print-style.scss */
.body a:link, .body a:visited, .body a {
  background: transparent;
  color: #2C51DF;
  font-weight: bold;
  text-decoration: underline;
  text-align: left; }

/* line 134, resources/assets/css/modules/_print-style.scss */
.body a[href^=http]:after {
  content: " <" attr(href) "> "; }

/* line 135, resources/assets/css/modules/_print-style.scss */
.body article a[href^="#"]:after {
  content: ""; }

/* line 136, resources/assets/css/modules/_print-style.scss */
.body a:not(:local-link):after {
  content: " <" attr(href) "> "; }

/* line 138, resources/assets/css/modules/_print-style.scss */
.footnotes {
  page-break-before: always; }
  /* line 140, resources/assets/css/modules/_print-style.scss */
  .footnotes ol, .footnotes ol > li {
    list-style: none; }
  /* line 143, resources/assets/css/modules/_print-style.scss */
  .footnotes ol {
    counter-reset: li; }
    /* line 145, resources/assets/css/modules/_print-style.scss */
    .footnotes ol li {
      position: relative;
      padding-left: 1.8em;
      margin-bottom: .5em; }
      /* line 149, resources/assets/css/modules/_print-style.scss */
      .footnotes ol li:before {
        content: counter(li);
        counter-increment: li;
        position: absolute;
        left: 0;
        color: rgba(0, 0, 0, 0.5); }
  /* line 158, resources/assets/css/modules/_print-style.scss */
  .footnotes li,
  .footnotes p {
    font-size: 7pt;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left; }

/*------------------------------------*  #HTML-PDF
\*------------------------------------*/
@page :first {
  margin: 2cm 2cm;
  /* line 19, resources/assets/css/print.scss */
  .p-footer {
    display: none; } }

/* line 24, resources/assets/css/print.scss */
.p-article__first-page {
  page-break-after: always; }
  /* line 26, resources/assets/css/print.scss */
  .p-article__first-page h1,
  .p-article__first-page h2 {
    font-weight: normal; }

/* line 32, resources/assets/css/print.scss */
.p-logo {
  display: block;
  width: 4cm;
  height: auto;
  margin-bottom: 1cm; }

/* line 38, resources/assets/css/print.scss */
.p-logo-2 {
  display: block;
  width: 25mm;
  height: auto;
  margin-top: 3mm; }

/* line 45, resources/assets/css/print.scss */
.p-article__header {
  padding: 0;
  margin-bottom: 1cm;
  text-align: center; }
  /* line 49, resources/assets/css/print.scss */
  .p-article__header .p-article__title,
  .p-article__header .p-article__author,
  .p-article__header .p-article__revue {
    margin: 0; }
  /* line 54, resources/assets/css/print.scss */
  .p-article__header .p-article__title {
    margin-top: 3mm;
    margin-bottom: 3mm; }
  /* line 58, resources/assets/css/print.scss */
  .p-article__header p {
    text-align: center; }

/* line 63, resources/assets/css/print.scss */
.p-article__header {
  border-top: 1.5mm solid #000000;
  border-bottom: 1.5mm solid #000000; }
  /* line 66, resources/assets/css/print.scss */
  .p-article__header.bg-dossier, .p-article__header.bg-3 {
    border-color: #FFF5B7;
    background-color: rgba(255, 245, 183, 0.4) !important; }
  /* line 71, resources/assets/css/print.scss */
  .p-article__header.bg-debats, .p-article__header.bg-4 {
    border-color: #E2B2B2;
    background-color: rgba(226, 178, 178, 0.2) !important; }
  /* line 76, resources/assets/css/print.scss */
  .p-article__header.bg-pop, .p-article__header.bg-5 {
    border-color: #ABBDC8;
    background-color: rgba(171, 189, 200, 0.2) !important; }
  /* line 81, resources/assets/css/print.scss */
  .p-article__header.bg-memoires, .p-article__header.bg-6 {
    border-color: #F7CD96;
    background-color: rgba(247, 205, 150, 0.2) !important; }
  /* line 86, resources/assets/css/print.scss */
  .p-article__header.bg-theses, .p-article__header.bg-7 {
    border-color: #B0DFBF;
    background-color: rgba(176, 223, 191, 0.2) !important; }

/* line 93, resources/assets/css/print.scss */
.p-article-header__inner {
  padding: 1cm; }

/* line 98, resources/assets/css/print.scss */
.p-article__info p {
  margin-top: 0; }

/* line 101, resources/assets/css/print.scss */
.p-article__info h4 {
  margin-top: .5cm;
  margin-bottom: .15cm; }

/* line 105, resources/assets/css/print.scss */
.p-article__info hr {
  margin-top: 1cm; }

/* line 110, resources/assets/css/print.scss */
.p-sep {
  padding-bottom: .5cm;
  margin-bottom: .5cm;
  border-bottom: 0.015cm solid rgba(0, 0, 0, 0.3); }

/* line 116, resources/assets/css/print.scss */
.p-footer {
  position: fixed;
  bottom: -2cm;
  left: 0;
  right: 0;
  height: 2cm; }
  /* line 122, resources/assets/css/print.scss */
  .p-footer .page-number {
    text-align: center;
    display: block;
    width: 100%; }
    /* line 126, resources/assets/css/print.scss */
    .p-footer .page-number:after {
      content: counter(page); }

/* line 132, resources/assets/css/print.scss */
.footnote-backref {
  font-size: 0; }

/* line 136, resources/assets/css/print.scss */
.p-article__content a.footnote-ref {
  color: #000000;
  text-decoration: none;
  font-size: 7pt;
  padding-left: 1pt;
  vertical-align: super !important; }

/* line 143, resources/assets/css/print.scss */
.p-article__content .intro {
  margin-bottom: 5mm;
  font-size: 11pt; }

/* line 147, resources/assets/css/print.scss */
.p-article__content p {
  widows: 3;
  orphans: 3; }

/* line 148, resources/assets/css/print.scss */
.p-article__content ul,
.p-article__content ol,
.p-article__content li {
  margin: 0;
  padding: 0; }

/* line 154, resources/assets/css/print.scss */
.p-article__content img {
  page-break-inside: avoid;
  max-width: 100%;
  height: auto; }

/* line 166, resources/assets/css/print.scss */
table {
  width: 100%;
  border-top: 0.02cm solid #000000;
  border-bottom: 0.02cm solid #000000;
  margin-top: 1cm;
  margin-bottom: 1cm;
  page-break-inside: avoid; }
  /* line 173, resources/assets/css/print.scss */
  table thead {
    border-bottom: 0.02cm solid #000000; }
  /* line 177, resources/assets/css/print.scss */
  table tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  /* line 180, resources/assets/css/print.scss */
  table tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.1); }
  /* line 184, resources/assets/css/print.scss */
  table tfoot {
    border-top: 1mm solid #000000; }
  /* line 187, resources/assets/css/print.scss */
  table th,
  table td {
    padding: 2mm;
    text-align: left;
    vertical-align: top;
    font-size: 9pt; }
  /* line 194, resources/assets/css/print.scss */
  table th,
  table th strong {
    font-size: 9pt; }
