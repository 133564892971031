/*------------------------------------*\
  #BACKGROUND
\*------------------------------------*/


.bg-dossier,
.bg-3 {
  background: $yellow !important;
}
.bg-debats,
.bg-4 {
  background: $red !important;
}
.bg-pop,
.bg-5 {
  background: $blue !important;
}
.bg-memoires,
.bg-6 {
  background: $orange !important;
}
.bg-theses,
.bg-7 {
  background: $green !important;
}
