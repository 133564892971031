/*------------------------------------*\
  #COLORS
\*------------------------------------*/


// Main colour palette
$white            : #FFFFFF;
$black            : #000000;
$gray             : #727272;
$gray-light       : #F0F0F0;
$yellow           : #FFF5B7;
$red              : #E2B2B2;
$blue             : #ABBDC8;
$orange           : #F7CD96;
$green            : #B0DFBF;


// Assign colours to variable roles
$body-color       : $white;
$base-color       : $black;
$action-response  : $gray;
